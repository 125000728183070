	/*
  	Flaticon icon font: Flaticon
  	Creation date: 19/06/2020 07:04
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-gamepad:before { content: "\f100"; }
.flaticon-chess:before { content: "\f101"; }
.flaticon-tickets:before { content: "\f102"; }
.flaticon-theater:before { content: "\f103"; }
.flaticon-cinema:before { content: "\f104"; }
.flaticon-seats-at-the-hall:before { content: "\f105"; }
.flaticon-meeting:before { content: "\f106"; }
.flaticon-round-table:before { content: "\f107"; }
.flaticon-boxing-gloves:before { content: "\f108"; }
.flaticon-stationary-bike:before { content: "\f109"; }
.flaticon-strength:before { content: "\f10a"; }
.flaticon-squash-rackets:before { content: "\f10b"; }
.flaticon-tennis:before { content: "\f10c"; }
.flaticon-relax:before { content: "\f10d"; }
.flaticon-massage:before { content: "\f10e"; }
.flaticon-massage-1:before { content: "\f10f"; }
.flaticon-massage-2:before { content: "\f110"; }
.flaticon-shuttlecock:before { content: "\f111"; }
.flaticon-badminton:before { content: "\f112"; }
.flaticon-badminton-1:before { content: "\f113"; }
.flaticon-reading-book:before { content: "\f114"; }
.flaticon-juggler:before { content: "\f115"; }
.flaticon-book:before { content: "\f116"; }
.flaticon-drama:before { content: "\f117"; }
.flaticon-working:before { content: "\f118"; }
.flaticon-books:before { content: "\f119"; }
.flaticon-knight:before { content: "\f11a"; }
.flaticon-chesspieces:before { content: "\f11b"; }
.flaticon-dice:before { content: "\f11c"; }
.flaticon-fountain:before { content: "\f11d"; }
.flaticon-fountain-1:before { content: "\f11e"; }
.flaticon-grandfather:before { content: "\f11f"; }
.flaticon-basketball:before { content: "\f120"; }
.flaticon-menu:before { content: "\f121"; }
.flaticon-coffee-break:before { content: "\f122"; }
.flaticon-coffee-cup:before { content: "\f123"; }
.flaticon-outdoor-table:before { content: "\f124"; }
.flaticon-sofa:before { content: "\f125"; }
.flaticon-green:before { content: "\f126"; }
.flaticon-grass:before { content: "\f127"; }
.flaticon-pool:before { content: "\f128"; }
.flaticon-swimming-pool:before { content: "\f129"; }
.flaticon-swimming:before { content: "\f12a"; }
.flaticon-swimming-pool-1:before { content: "\f12b"; }
.flaticon-basketball-1:before { content: "\f12c"; }
.flaticon-basketball-2:before { content: "\f12d"; }
.flaticon-slider:before { content: "\f12e"; }
.flaticon-seesaw:before { content: "\f12f"; }
.flaticon-playground:before { content: "\f130"; }
.flaticon-running:before { content: "\f131"; }
.flaticon-jogging:before { content: "\f132"; }
.flaticon-jogging-1:before { content: "\f133"; }
.flaticon-cricket:before { content: "\f134"; }
.flaticon-cricket-1:before { content: "\f135"; }
.flaticon-car:before { content: "\f136"; }
.flaticon-taxi:before { content: "\f137"; }
.flaticon-toilet:before { content: "\f138"; }
.flaticon-litter-box:before { content: "\f139"; }
.flaticon-selection:before { content: "\f13a"; }
.flaticon-bathtub:before { content: "\f13b"; }
.flaticon-bathtub-1:before { content: "\f13c"; }
.flaticon-bed:before { content: "\f13d"; }
.flaticon-bed-1:before { content: "\f13e"; }
.flaticon-double-bed:before { content: "\f13f"; }
.flaticon-bed-2:before { content: "\f140"; }
.flaticon-bed-3:before { content: "\f141"; }
.flaticon-balcony:before { content: "\f142"; }
.flaticon-balcony-1:before { content: "\f143"; }
.flaticon-balcony-2:before { content: "\f144"; }